import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
  ButtonWrapper,
} from "../../../components/Layout/styled"

import Footer from "../../../components/Layout/Footer"

const communique = ({ data, intl }) => {
  return (
    <Fragment>
      <SectionContainer>
        <SectionInnerWrapper align="flex-start" justify="flex-start">
          <TextBox>
            <p>
              Dans une démarche d’amélioration continue, nous travaillons
              actuellement sur les pages liées aux différentes pathologies avec
              des professionnels de santé partenaires.
            </p>
            <ButtonWrapper to="/">Accéder à percko.com</ButtonWrapper>
            {/* <CommuniqueLink to="/communique/">
              Voir le communiqué
            </CommuniqueLink> */}
          </TextBox>
        </SectionInnerWrapper>
      </SectionContainer>
      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )
}

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h2 {
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    margin: 0px;
  }

  > p {
    margin-bottom: 1.3em;
    color: #262626;
  }
`

const CommuniqueLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  color: #262626;
  text-decoration: underline;
  border: 1px solid transparent;
  vertical-align: middle;
  border-radius: 5px;
  margin-top: 0;
  margin-right: 1em;
  text-shadow: none;
  line-height: 2.4em;
  box-sizing: border-box;
  margin-bottom: 1em;

  @media (max-width: 550px) {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
  }

  :hover {
    color: #262626;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "mentions_legales" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(communique)
